import React, { Component } from "react";
import { Button, Col, Row, Card, Container } from "react-bootstrap";
import configData from "../config.json";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./Proposerinfo.css";
export default class NomineeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forgotPasswordCondition: false,
      chpiId: sessionStorage.getItem("chpiId"),
      proposalId: sessionStorage.getItem("proposalId"),
      baseproductId: sessionStorage.getItem("baseproductId"),
      cqrId: sessionStorage.getItem("cqrId"),
      nominess: [],
      tab1Data: {
        name: "",
        dob: "",
        relation: "",
        mobile: "",
      },
      minDate: "",
      maxDate: "",
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  handleInputChange = (tab, field, value) => {
    if (tab === "tab1Data" && field === "name") {
      // Allow letters and spaces in the name, including an empty value
      const lettersAndSpacesRegex = /^[A-Za-z\s]*$/;

      if (!lettersAndSpacesRegex.test(value.trim())) {
        toast.error("Not allowed special characters and Numbers.");
        return; // Stop further processing for this field
      }
    }
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
  };

  handleInputMChange = (tab, field, value) => {
    var error = "no";
    if (tab === "tab1Data" && field === "mobile") {
      // Allow only letters in the company name (no numbers)
      const firstDigit = parseInt(value.charAt(0), 10);
      if (firstDigit < 6 || firstDigit > 9) {
        // Display an error toast message or handle the error as needed
        toast.error("Please enter valid mobile number.");
        return; // Stop further processing for this field
      }
    }
    if (value.length < 1 || value.length > 10) {
      error = "yes";
      toast.error("Please enter valid mobile number.");
    }

    // console.log(error);
    if (error !== "yes") {
      this.setState((prevState) => ({
        [tab]: {
          ...prevState[tab],
          [field]: value,
        },
      }));
    }
  };
  validateForm() {
    const { tab1Data } = this.state;
    const currentDate = new Date();
    const maxDate = new Date();
    maxDate.setFullYear(currentDate.getFullYear() - 98);
    let formIsValid = true;
    if (!tab1Data["name"]) {
      formIsValid = false;
      toast.error("Please Enter Nominee Name.");
    } else if (tab1Data["name"].length > 50) {
      formIsValid = false;
      toast.error("Please Enter Name Should 50 Characters.");
    } else if (!tab1Data["dob"]) {
      formIsValid = false;
      toast.error("Please Enter Date of Birth.");
    } else {
      const dob = new Date(tab1Data["dob"]);

      // Check if DOB is more than 99 years ago or if it's tomorrow's date
      if (dob >= currentDate || dob <= maxDate) {
        formIsValid = false;
        toast.error(
          "Invalid Date of Birth. Age must be below 98 years and not tomorrow's date."
        );
      }
    }
    if (!tab1Data["relation"]) {
      formIsValid = false;
      toast.error("Please Enter Relation Of Nominee.");
    } else if (!tab1Data["mobile"]) {
      formIsValid = false;
      toast.error("Enter Enter Mobile Number.");
    } else if (tab1Data["mobile"].length !== 10) {
      formIsValid = false;
      toast.error("Please Enter Valid Number.");
    }
    return formIsValid;
  }
  handleSubmitBackClick = (e) => {
    window.location.href = "/medicals";
  };
  handleSubmitNextClick = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      var fd = new FormData();
      fd.append("proposalId", this.state.proposalId);
      fd.append("baseproductId", this.state.baseproductId);
      fd.append("name", this.state.tab1Data.name);
      fd.append("dob", this.state.tab1Data.dob);
      fd.append("relation", this.state.tab1Data.relation);
      fd.append("mobile", this.state.tab1Data.mobile);
      fetch(configData.api_url + "leads/updatenominee/", {
        method: "POST",
        headers: {},
        mode: "cors",
        body: fd, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            window.location.href = "/addons";
          }
        });
    }
  };
  componentDidMount() {
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 18);
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 98);
    this.setState({ minDate: maxDate, maxDate: minDate });
    this.HealthnomineerelationsfetchHandler();
  }
  HealthnomineerelationsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "C_Healthnomineerelations/listByid/", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ nominess: data.list });
        }
      });
  };
  render() {
    // const currentDate = new Date().toISOString().split("T")[0];
    return (
      <Container>
        <Row id="MainRoOne">
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-fill"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Proposer Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-vcard-fill"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Member Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        onClick={this.handleSubmitBackClick}
                        className="bi bi-heart-pulse"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6
                        style={{ color: "#3d5a95" }}
                        onClick={this.handleSubmitBackClick}
                      >
                        Medicals
                      </h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-pencil-square"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Nominee Details</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span className="bi bi-arrow-right" id="inActiveArrow"></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-clipboard-plus"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Addon's</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}></Col>
            </Row>
          </Col>
        </Row>
        <Card>
          <Card.Body>
            <Row>
              <Col>
              <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Name of Nominee *</th>
                      <th scope="col">Date of Birth *</th>
                      <th scope="col">Relation with Proposer *</th>
                      <th scope="col">Mobile of Nominee *</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key="nominee">
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          id="name "
                          name="name"
                          value={this.state.tab1Data.name}
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "name",
                              e.target.value
                            )
                          }
                          aria-describedby="name"
                          placeholder="Nominee Name"
                        />
                      </td>
                      <td>
                        <DatePicker
                          className="form-control"
                          selected={this.state.tab1Data.dob}
                          onChange={(date) =>
                            this.handleInputChange(
                              "tab1Data",
                              "dob",
                              date // Date object
                            )
                          }
                          minDate={this.state.minDate}
                          maxDate={this.state.maxDate}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Date Of Birth"
                        />

                        {/* <input
                          type="date"
                          className="form-control"
                          id="dateOfBirth"
                          value={this.state.tab1Data.dob}
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "dob",
                              e.target.value
                            )
                          }
                          name="dob"
                          min={this.state.minDate}
                          max={this.state.maxDate}
                          aria-describedby="dob"
                          placeholder="Date Of Birth"
                        /> */}
                      </td>
                      <td>
                        <select
                          className="form-control"
                          name="relation"
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "relation",
                              e.target.value
                            )
                          }
                          value={this.state.tab1Data.relation}
                        >
                          <option>Select</option>
                          {this.state.nominess.map((quote, index) => (
                            <option key={index} value={quote.nomineecode}>
                              {quote.nomineerelation}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          id="mobile"
                          value={this.state.tab1Data.mobile}
                          onChange={(e) =>
                            this.handleInputMChange(
                              "tab1Data",
                              "mobile",
                              e.target.value
                            )
                          }
                          name="mobile"
                          aria-describedby="mobile"
                          placeholder="Nominee Number"
                          maxLength={10}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <br />

        <Row className="mt-5">
          <Col>
            {" "}
            <Button
              id="getQuotes4"
              onClick={this.handleSubmitBackClick}
              type="submit"
              style={{ float: "left" }}
            >
              Back
            </Button>
          </Col>
          <Col>
            {" "}
            <Button
              id="getQuotes4"
              onClick={this.handleSubmitNextClick}
              type="submit"
              style={{ float: "right" }}
            >
              Next
            </Button>
          </Col>
          {/* </Form> */}
        </Row>
      </Container>
    );
  }
}
